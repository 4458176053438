import logo from './logo.svg';
import './App.css';
import BackgroundVideo from './components/BackgroudVideo';
import BubbleCard from './components/BubbleCard';
import CoreCard from './components/core/content/CoreCard';
import Select from './components/Select';

import { connect } from 'react-redux';
import { devicesGet, devicesPut } from './actions';
import { useEffect, useState } from 'react';
import { apiRequestState, get } from './utility/utility';
import Button from './components/core/input/Button';
import CoreInputCheckbox from './components/core/input/CoreInputCheckbox';
import SectionDivider from './components/SectionDivider';

const branding = {
  name: 'tens',
};

// Define the colors object
const colors = {
  primaryLight: '#3798EF',
  primaryMedium: '#3A81C1',
  primaryDark: '#215380',

  secondaryLight: '#00ff00',
  secondaryMedium: '#00ff00',
  secondaryDark: '#00ff00',

  tertiaryLight: '#8AD1A9',
  tertiaryMedium: '#8AD1A9',
  tertiaryDark: '#8AD1A9',

  grey000: '#F7F7F7',
  grey050: '#EDEDED',
  grey100: '#acacac',
  grey300: '#E6E6E6',
  grey500: '#C5C5C6',
  grey700: '#A1A1A1',
  grey900: '#464953',
  grey1100: '#1F2021',

  mapDarkBackround: '#191A1A',

  warning: 'orange',
  critical: 'red',
};

// Apply colors to CSS
Object.entries(colors).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--color-${key}`, value);
});

function App({ devicesGetRequestState, devices, dispatchDevicesGet, dispatchDevicesPut }) {
  const [inputState, setInputState] = useState({ programs: [] });

  useEffect(() => {
    dispatchDevicesGet();
  }, []);

  useEffect(() => {
    if (devicesGetRequestState === apiRequestState.SUCCESS) {
      setInputState(devices);
    }
  }, [
    devicesGetRequestState]);

  useEffect(() => {
    switch (get(devices, 'model').toLowerCase()) {
      case 'nova':
        colors.primaryLight = '#5A9BD6';   // Lighter version of #3A81C1
        colors.primaryMedium = '#3A81C1';  // Base color
        colors.primaryDark = '#2A6191';    // Darker version of #3A81C1
        break;

      case 'eva':
        colors.primaryLight = '#FFA1D6';   // Lighter version of #FF81C1
        colors.primaryMedium = '#FF81C1';  // Base color
        colors.primaryDark = '#D16A9A';    // Darker version of #FF81C1
        break;

      case 'care':
        colors.primaryLight = '#9b4bcc';  // Lighter version of #591892
        colors.primaryMedium = '#591892'; // Base color
        colors.primaryDark = '#3f1268';   // Darker version of #591892
        break;

      case 'soul':
        colors.primaryLight = '#d5483d';  // Lighter version of #a91101
        colors.primaryMedium = '#a91101'; // Base color
        colors.primaryDark = '#7b0d01';   // Darker version of #a91101
        break;

      case 'stim':
        colors.primaryLight = '#3D3F42';   // Lighter version of #1F2021
        colors.primaryMedium = '#1F2021';  // Base color
        colors.primaryDark = '#121314';    // Darker version of #1F2021
        break;

      default:
        break;
    }

    // Apply colors to CSS
    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--color-${key}`, value);
    });

  }, [devices]);

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
  }

  // Helper function to handle checkbox changes
  const handleCheckboxChange = (event, programId) => {
    setInputState((prevState) => {
      const updatedPrograms = event.target.checked
        ? [...prevState.programs, programId] // Add programId if checked
        : prevState.programs.filter((id) => id !== programId); // Remove programId if unchecked

      return { ...prevState, programs: updatedPrograms };
    });
  };

  const onButtonClick = () => {
    dispatchDevicesPut(inputState)
  }

  const programs = [
    { id: 'P0', name: 'Endurance avec repos actif' },
    { id: 'P1', name: 'Burst' },
    { id: 'P2', name: 'Muscles dénervés' },
    { id: 'P3', name: 'Interferentiel' },
    { id: 'P4', name: 'Micro-courant' },
  ];

  return (
    <div className="App">
      <BackgroundVideo videoSrc="wallpaper.mov" />
      <BubbleCard
        title={get(devices, 'model').toUpperCase()}
        style={{ width: '50%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',   // Stack elements vertically
            alignItems: 'center',      // Center the container horizontally
            width: '85%',
            gap: '10px',               // Space between elements
            marginTop: '10px',         // Optional: spacing from the top
            marginBottom: '20px',         // Optional: spacing from the top
          }}
        >
          <div style={{ width: '100%', padding: '0px', margin: '0px' }}>
            <SectionDivider label="Modèle" />
          </div>
          <div style={{ width: '100%', padding: '0px', margin: '0px' }}>
            <Select
              id="model"
              value={get(inputState, 'model')}
              onChange={onInputChange}
              options={['Nova', 'Eva', 'Stim', 'Care', 'Soul']}
              placeholder="Model"
            />

          </div>
          <div style={{ width: '100%' }}>
            <SectionDivider label="Programmes" />
          </div>
          <div style={{ width: '100%', color: 'var(--color-grey900)', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '20px', paddingBottom: '5%' }}>
            {programs.map((program) => (
              <CoreInputCheckbox
                key={program.id}
                id={program.id}
                onChange={(event) => handleCheckboxChange(event, program.id)}
                checked={inputState.programs.includes(program.id)}
              >
                {program.name}
              </CoreInputCheckbox>
            ))}
          </div>
          <Button primary onClick={onButtonClick}>Sauvegarder</Button>
        </div>
      </BubbleCard>

    </div>
  );
}

const mapStateToProps = (state) => ({
  devices: state.devices.devices,
  devicesGetRequestState: state.devices.devicesGetRequestState,

});

const mapDispatchToProps = (dispatch) => ({
  dispatchDevicesGet: () => dispatch(devicesGet()),
  dispatchDevicesPut: (device) => dispatch(devicesPut(device)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
