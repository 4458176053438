import React from 'react';
import './BubbleCard.css';

function BubbleCard({ title, children, style = { margin: '25px' } }) {
  return (
    <div className="bubble-card-container" style={style}>
      {!!title && (
        <div className="bubble-card-title-block">
          <div className="bubble-card-title">
            {title}
          </div>
          <div className="bubble-card-subtitle">
            TENS 4.0
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          alignItems: 'center',    // Center horizontally
          justifyContent: 'center', // Center vertically
          width: '100%',           // Full width to allow proper centering
          minHeight: '200px',      // Optional: adjust based on desired height
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default BubbleCard;
