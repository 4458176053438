import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import notifications from './notifications';
import devices from './devices';

const appReducer = combineReducers({
  notifications,
  devices,
  routing,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // Reset state to initial state on logout
    return initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
