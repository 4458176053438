import {
  DEVICES_GET_API_CALL_START,
  DEVICES_GET_API_CALL_FAIL,
  DEVICES_GET_API_CALL_SUCCESS,
  DEVICES_GET_API_CALL_IDLE,
  DEVICES_PUT_API_CALL_START,
  DEVICES_PUT_API_CALL_FAIL,
  DEVICES_PUT_API_CALL_SUCCESS,
  DEVICES_PUT_API_CALL_IDLE,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';

const initialState = {
  devicesPutRequestState: apiRequestState.IDLE,
  devicesGetRequestState: apiRequestState.IDLE,
};

const devicesPutStart = (state, action) => updateObject(state, {
  devicesPutRequestState: action.requestState,
});

const devicesPutFail = (state, action) => updateObject(state, {
  devicesPutRequestState: action.requestState,
});

const devicesPutSuccess = (state, action) => updateObject(state, {
  devices: action.device,
  devicesPutRequestState: action.requestState,
});

const devicesPutIdle = (state, action) => updateObject(state, {
  devicesPutRequestState: action.requestState,
});

const devicesGetStart = (state, action) => updateObject(state, {
  devicesGetRequestState: action.requestState,
});

const devicesGetFail = (state, action) => updateObject(state, {
  devicesGetRequestState: action.requestState,
});

const devicesGetSuccess = (state, action) => updateObject(state, {
  devices: action.data,
  devicesGetRequestState: action.requestState,
});

const devicesGetIdle = (state, action) => updateObject(state, {
  devicesDeleteRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVICES_PUT_API_CALL_START:
      return devicesPutStart(state, action);
    case DEVICES_PUT_API_CALL_FAIL:
      return devicesPutFail(state, action);
    case DEVICES_PUT_API_CALL_SUCCESS:
      return devicesPutSuccess(state, action);
    case DEVICES_PUT_API_CALL_IDLE:
      return devicesPutIdle(state, action);
    case DEVICES_GET_API_CALL_START:
      return devicesGetStart(state, action);
    case DEVICES_GET_API_CALL_FAIL:
      return devicesGetFail(state, action);
    case DEVICES_GET_API_CALL_SUCCESS:
      return devicesGetSuccess(state, action);
    case DEVICES_GET_API_CALL_IDLE:
      return devicesGetIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
