// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    filter: brightness(95%);
    overflow: hidden;
}

.video-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
}

@media (max-width: 600px) {
    .video-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        /* 16:9 aspect ratio */
        overflow: hidden;
    }

    .video-container video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/BackgroundVideo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,eAAe;AACnB;;AAEA;IACI;QACI,kBAAkB;QAClB,WAAW;QACX,SAAS;QACT,sBAAsB;QACtB,sBAAsB;QACtB,gBAAgB;IACpB;;IAEA;QACI,eAAe;QACf,MAAM;QACN,OAAO;QACP,WAAW;QACX,YAAY;QACZ,iBAAiB;QACjB,WAAW;IACf;AACJ","sourcesContent":[".video-container {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    min-width: 100%;\n    min-height: 100%;\n    z-index: -1;\n    filter: brightness(95%);\n    overflow: hidden;\n}\n\n.video-container video {\n    /* Make video to at least 100% wide and tall */\n    min-width: 100%;\n}\n\n@media (max-width: 600px) {\n    .video-container {\n        position: relative;\n        width: 100%;\n        height: 0;\n        padding-bottom: 56.25%;\n        /* 16:9 aspect ratio */\n        overflow: hidden;\n    }\n\n    .video-container video {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        z-index: -1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
