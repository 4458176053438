import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { API_BASE_URL } from '../config';
import { apiRequestState, get } from '../utility/utility';
import { notificationsShow } from './notifications';

export const DEVICES_PATH = 'devices';
export const DEVICES_GET_API_CALL_START = 'DEVICES_GET_API_CALL_START';
export const DEVICES_GET_API_CALL_SUCCESS = 'DEVICES_GET_API_CALL_SUCCESS';
export const DEVICES_GET_API_CALL_FAIL = 'DEVICES_GET_API_CALL_FAIL';
export const DEVICES_GET_API_CALL_IDLE = 'DEVICES_GET_API_CALL_IDLE';
export const DEVICES_PUT_API_CALL_START = 'DEVICES_PUT_API_CALL_START';
export const DEVICES_PUT_API_CALL_SUCCESS = 'DEVICES_PUT_API_CALL_SUCCESS';
export const DEVICES_PUT_API_CALL_FAIL = 'DEVICES_PUT_API_CALL_FAIL';
export const DEVICES_PUT_API_CALL_IDLE = 'DEVICES_PUT_API_CALL_IDLE';

export const devicesGetStart = () => ({
  type: DEVICES_GET_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const devicesGetSuccess = (data) => ({
  type: DEVICES_GET_API_CALL_SUCCESS,
  data,
  requestState: apiRequestState.SUCCESS,
});

export const devicesGetFail = () => ({
  type: DEVICES_GET_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const devicesGet = () => (dispatch) => {  
  dispatch(devicesGetStart());

  const devicesGetRequest = async () => {
    try {      
      const response = await axios.get(
        `${API_BASE_URL}/${DEVICES_PATH}/`,
      );
      dispatch(devicesGetSuccess(response.data));
    } catch (err) {
      if ((get(err, 'response.status') === StatusCodes.UNAUTHORIZED)) {
        // dispatch(signOut());
      }
      dispatch(devicesGetFail());
    }
  };

  devicesGetRequest();
};

export const devicesPutStart = () => ({
  type: DEVICES_PUT_API_CALL_START,
  requestState: apiRequestState.LOADING,
});

export const devicesPutSuccess = (device) => ({
  type: DEVICES_PUT_API_CALL_SUCCESS,
  device,
  requestState: apiRequestState.SUCCESS,
});

export const devicesPutIdle = () => ({
  type: DEVICES_PUT_API_CALL_IDLE,
  requestState: apiRequestState.IDLE,
});

export const devicesPutFail = () => ({
  type: DEVICES_PUT_API_CALL_FAIL,
  requestState: apiRequestState.FAIL,
});

export const devicesPut = (device) => (dispatch) => {
  dispatch(devicesPutStart());

  const devicesPutRequest = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/${DEVICES_PATH}`, device);
      await dispatch(devicesPutSuccess(response.data));
      await dispatch(devicesPutIdle());
      dispatch(notificationsShow('success', `Successfully updated device config`));
    } catch (err) {
      dispatch(devicesPutFail());
      dispatch(notificationsShow('warning', `${err.response.data}`));
    }
  };

  devicesPutRequest();
};
