// Development settings
const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const IS_DEVELOPMENT = NODE_ENV === 'development';

const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL || 'http';
const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;

// console.log('NODE_ENV', NODE_ENV);
// console.log('API_PROTOCOL', API_PROTOCOL);
// console.log('API_HOST', API_HOST);
// console.log('API_PORT', API_PORT);

// Determine API Base URL
const API_BASE_URL_DEFAULT = process.env.REACT_APP_API_BASE_URL;
// eslint-disable-next-line
const API_BASE_URL = IS_DEVELOPMENT
  ? `${API_PROTOCOL}://${API_HOST}:${API_PORT}`
  : API_BASE_URL_DEFAULT;

// Third-party services
const REDUX_DEV_TOOLS_ARE_ENABLED = process.env.REACT_APP_REDUX_DEV_TOOLS_ARE_ENABLED === 'true';

export {
  API_BASE_URL,
  REDUX_DEV_TOOLS_ARE_ENABLED,
};
